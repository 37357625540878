import React from 'react';

import { HeroBanner, CaseHeader, TextContent, Gallery, GalleryLayout, Team } from 'modules/references';

import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';
import socialPreview from '../../../static/social-previews/shipmonk.png';

const CASE_ID = 'shipmonk';
const MODULE_NAME = 'shipmonk';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    black: '#000',
    blueish: '#85A4C8',
    green: '#6ED591',
    white: '#FFF',
};

const team = [
    { department: 'references.case.team.management', names: ['Tomáš Marek'] },
    {
        department: 'references.case.team.ios',
        names: ['Vladislav Komkov', 'Jakub Olejník'],
    },
    {
        department: 'references.case.team.android',
        names: ['Petr Polívka', 'Jan Mottl'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Mariya Vorona'],
    },
];

interface Props {}
const Shipmonk = (props: Props) => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero_main.png`,
                    imgStyle: { objectFit: 'contain', objectPosition: 'bottom center' },
                    style: {
                        width: '100%',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero_bg.png`,
                imgStyle: { objectFit: 'cover', objectPosition: 'bottom' },
            }}
            headerTheme="dark"
            background={colors.white}
            textColor={colors.black}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.blueish}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DELIVERY,
                outputs: [
                    'references.case.output.ios',
                    'references.case.output.android',
                    'references.case.output.testing',
                ],
            }}
            background={colors.white}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/gallery_01_main.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom left',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/gallery_01_bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'bottom',
                },
                style: {
                    height: '70%',
                    bottom: 0,
                },
            }}
            background={colors.white}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.green}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/gallery_02_main.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                        style: {
                            marginTop: '8rem',
                            marginBottom: '-5rem',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/gallery_02_bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                    objectPosition: 'top',
                },
            }}
            background={`linear-gradient(180deg, ${colors.green} 0%, ${colors.white} 100%)`}
        />
        <TextContent
            headerTheme="dark"
            textColor={colors.blueish}
            content={{
                section: `case.${CASE_ID}.section5.name`,
                title: `case.${CASE_ID}.section5.title`,
                text: `case.${CASE_ID}.section5.text`,
                image: {
                    src: `${IMAGE_PATH}/gallery_03_main.png`,
                    imgStyle: {
                        objectFit: 'contain',
                        objectPosition: 'center right',
                    },
                    style: {
                        marginBottom: '-8rem',
                        marginLeft: '2rem',
                        maxWidth: '70vw',
                    },
                },
            }}
            background={colors.white}
        />

        {/*         
        // The content is not ready yet
        <Testimonial
            headerTheme="dark"
            background={`linear-gradient(180deg, ${colors.green} 0%, ${colors.blueish} 100%)`}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/testimonial.png`,
                    }
                },
            }}
            type="PROFILE_RIGHT"
            textColor={colors.white}
        /> */}
        {/* @ts-expect-error */}
        <Team
            headerTheme="light"
            content={team}
            background={`linear-gradient(180deg, ${colors.green} 0%, ${colors.blueish} 100%)`}
            textColor={colors.white}
        />
    </>
);

export default Shipmonk;
